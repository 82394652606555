<template>
  <TopNav/>
</template>

<script>
import TopNav from "@/components/TopNav";

export default {
  name: 'Header',
  components: {
    TopNav
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
