<template>
  <section class="container mb-4">
    <div class="text-center"><img src="/images/1.jpg" class="img-fluid mb-3"></div>
    <h2 class="text-center fst-italic">Private Instruction in a Home School Setting</h2>
    <p>At Love to Learn, we offer a variety of classes that teach the basics for Elementary age students.  We offer Math, Language Arts, Elementary Science, and Exploring Engineering.  These classes can be taken individually, or together, as a complete curriculum.</p>

    <div class="mb-4 border rounded shadow p-4 bg-white" v-if="yearRange">
      <h2 class="text-center text-danger">Classes for {{yearRange}} school season are full.</h2>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/3.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Math</h5>
      <p>We utilize elementary age Saxon math curriculum and will place your child in the appropriate level for their skill. (Ages 7-10) Classes are daily at 9am, Mon – Fri from Aug 23, 2021 – May 20, 2022.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/4.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Language Arts</h5>
      <p>We will learn handwriting, basic writing skills, grammar, and spelling. (Ages 7-10) Classes are daily at 10am, Mon – Fri from Aug 23, 2021 – May 20, 2022.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/2.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Elementary Science:</h5>
      <p>We will learn all about the world around us as we work towards understanding basic science through everything FUN! (Ages 7 – 10) Classes are daily at 11am, Mon – Thur from Aug 23, 2021 – May 20, 2022.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/7.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Exploring Engineering:</h5>
      <p>This class uses all the best building, creating and engineering sets to give children a well-rounded first experience in how engineering is used in every-day life! (Ages 7 – 10) Classes are at 11am on Fridays from Aug 23, 2021 – May 20, 2022.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/8.jpg" class="img-fluid"></div>
      <h5 class="mb-3">About Our Teacher / School:</h5>
      <p>As a Homeschooling mother for 17 years, I realized that I could expand outside of my own children, and create a safe, fun, learning environment for other children as well.  The plan has been a success for the last two years, as the children enjoy the homey atmosphere, companionship of each other, and a relaxed teaching style.  I believe that each child can experience success and growth along their learning path, because I have seen it happen for almost two decades.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/6.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Location:</h5>
      <p>Highland, Ut (Exact Address given upon registration)</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/5.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Availability:</h5>
      <p>Classes are open to the public, but class size is limited and based on the needs of our students.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/9.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Tuition:</h5>
      <p>${{tuition}} per class, per school year.</p>
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm" v-if="yearRange">
      <div class="text-center mb-2"><img src="/images/11.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Registration:</h5>
      <h5 class="text-danger text-center">Registration for {{yearRange}} is closed</h5>
      <!--<div class="text-center">
        <button type="button" class="btn btn-lg btn-primary" disabled>Register</button>
      </div>-->
    </div>

    <div class="mb-4 border rounded p-4 shadow-sm">
      <div class="text-center mb-2"><img src="/images/10.jpg" class="img-fluid"></div>
      <h5 class="mb-3">Contact</h5>
      <p>For more information <a href="mailto:deannac@fusecast.com&subject=Home School">email</a> or call <a href="tel:8017873747">801-787-3747</a>.</p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      yearRange: null,
      tuition: 350
    }
  },
  mounted() {
    this.setYearRange();
  },
  methods: {
    setYearRange: function () {
      let dt = new Date();
      let thisYear = dt.getFullYear();
      let nextYear = thisYear + 1;
      let lastYear = thisYear - 1;
      let month = dt.getMonth();
      if(month < 5){
        this.yearRange = lastYear + "-" + thisYear;
      }else{
        this.yearRange = thisYear + "-" + nextYear;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
