<template>
  <nav id="topNavBar" class="navbar navbar-expand-lg fixed-top navbar-dark bg-dark shadow">
    <div class="container">
      <a class="navbar-brand" href="/">Love To Learn</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item" @click="closeNav()">
            <router-link class="nav-link" to="/">Home</router-link>
          </li>
          <li class="nav-item" @click="closeNav()">
            <router-link class="nav-link" to="/about">About</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "top_nav",
  methods: {
    //Close the nav bar if it's opened
    closeNav (){
      let navb = document.querySelector('.navbar-collapse');
      navb.classList.remove('show');
    }
  }
}
</script>

<style scoped>
 .navbar{
   background-color: rgba(170, 72, 72, 0.85) !important;
 }
 .router-link-exact-active {
   color: #FFFFFF !important;
 }
</style>