<template>
  <footer class="text-center small text-muted mb-5">
    &copy; {{year}} by Love To Learn, All rights reserved.
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
